import { initialiseSwiper } from './swiper/swiper.js';
import { initShowroomFinders } from './showrooms/showroomFinder.js';
import { RecentSwatches } from './swatch/RecentSwatches.js';
import { handleFragmentInit } from './pdp/fragments';
import { replaceElementHTML } from './pdp/replace';

initShowroomFinders();

const initRecentSwatches = () => {
  const recentSwatchContainer = document.querySelector(
    '[data-recent-swatches]',
  );

  if (!recentSwatchContainer) {
    return;
  }

  const recentSwatches = new RecentSwatches();
  const swatchGridModifierClasses = ['swiper-wrapper', 'swatch-grid--block'];

  recentSwatches.renderRecentSwatches(swatchGridModifierClasses);
};

initRecentSwatches();

handleFragmentInit(({ replace }) => {
  if (replace?.crossSell) {
    replaceElementHTML('.block--cross-sells', replace.crossSell);
  }

  const carousels = document.querySelectorAll('.block .swiper');
  // This is reasonable as we exit out if a swpier alreaddy exists. Though perhaps not ideal.
  for (const carousel of carousels ?? []) {
    initialiseSwiper(carousel);
  }
});
